import React from 'react';
import './User-Create-Update.scss'
import Modal from './../components/custom-components/modal/modal';
import AddPoiModal from '../components/modals/add-poi-modal/Add-Poi-Modal'
import history from '../../history'
import Checkbox from '../components/custom-components/checkbox/Checkbox';
import {AccountUser, Resource, UserModule, UserRole} from '../../data/models/AccountUser/user.model';
import userAdminService from '../../services/user-admin.service';
import Guid from '../../toolbox/guid';
import UserAdminService from "../../services/user-admin.service";


const UserRoleList = {
    LOCAL: 1,
    GROUP: 2,
    GLOBAL: 3,
};

const IXINA_CLIENT_IDS = ['7a035b8c-2f4b-11eb-a22c-bcee7b89f24a', 'f93bca20-685a-4989-a1f3-6dd719013d44'];

export interface Props {
    accountUser?: AccountUser;
}

interface State {
    canValidate: boolean;
    showPoiModal: boolean;
    showGroupModal: boolean;
    clientId: string;
    userId?: string;
    selectedRole: number;
    userRoleDetail: any;
    hasAllUserModules: boolean;
    poiList: string[];
    groupList: string[];
    currentPoiList: string[];
    currentGroupList: string[];
    newAccountUser: AccountUser;
    disableButtons: boolean;
}

class UserCreateUpdate extends React.Component<Props, State> {
    historyState: any = history.location.state;
    optionalBtn = [{
        btnTitle: 'Tout supprimer',
        btnStyle: 'customButton margin5 red-btn w120'
    }];

    constructor(props: Props) {
        super(props);
        const initialUser = this.props.accountUser || new AccountUser();

        initialUser.firstName = initialUser.firstName || "";
        initialUser.lastName = initialUser.lastName || "";
        initialUser.mail = initialUser.mail || "";
        this.state = {
            canValidate: false,
            showPoiModal: false,
            showGroupModal: false,
            clientId: this.historyState.clientId,
            userId: this.historyState.userId || '',
            selectedRole: 0,
            userRoleDetail: [],
            hasAllUserModules: false,
            poiList: [],
            groupList: [],
            currentPoiList: [],
            currentGroupList: [],
            newAccountUser: initialUser,
            disableButtons: false
        }
        this.handlePoiOpen = this.handlePoiOpen.bind(this);
        this.handleScopeSelection = this.handleScopeSelection.bind(this)
        this.handleUserFirstName = this.handleUserFirstName.bind(this);
        this.handleUserLastName = this.handleUserLastName.bind(this);
        this.handleUserMail = this.handleUserMail.bind(this);
        this.validateData = this.validateData.bind(this);
        this.initUserData = this.initUserData.bind(this);

    }

    componentDidMount() {
        this.initUserData();
    }

    fetchUserModules = async () => {
        try {
            const response = await UserAdminService.getUserModules();
            const userRoleDetailList = response.data
                .filter((userModule: UserModule) =>
                    IXINA_CLIENT_IDS.includes(this.state.clientId) || userModule.name !== 'franchise'
                )
                .map((userModule: UserModule) => ({
                    id: userModule.name,
                    label: userModule.label,
                    resources: userModule.resources.map((resource: Resource) => ({
                        id: resource.name,
                        label: resource.label,
                        selected: false,
                        enable: true
                    })),
                    selected: false,
                    enable: true,
                    open: false
                }));

            this.setState({
                userRoleDetail: userRoleDetailList
            });
        } catch (error) {
            console.error('Error fetching user Modules:', error);
        }
    }
    initUserData = async () => {
        await this.fetchUserModules();
        if (this.state.userId && Guid.isGuid(this.state.userId)) {
            try {
                const resp = await UserAdminService.getUserFromClient(this.state.clientId, this.state.userId);
                const user = resp.data.payload.data;
                const usr = new AccountUser();
                usr.id = user.id;
                usr.firstName = user.firstname;
                usr.lastName = user.lastname;
                usr.mail = user.email;
                usr.username = user.username;
                usr.status = user.status;
                usr.role = new UserRole();
                usr.role.scope = user.role.scope;
                usr.role.targets = user.role.targets;
                usr.role.subtargets = user.role.subtargets.map((sub: any) => sub.resourceName);
                usr.storeCodes = user.role.resources.POI;
                usr.groups = user.role.resources.POI_GROUP;
                usr.isSso = user.isSSO;

                const updatedUserRoleDetail = this.state.userRoleDetail.map((detail: any) => {
                    const isSelected = usr.role.targets.includes(detail.id);
                    let enabled = true;
                    if (usr.role.scope === 'global' && ['poi', 'campaign', 'gmb', 'gmb_post'].includes(detail.id)) {
                        enabled = false;
                    }
                    const resources = detail.resources.map((resource: any) => (
                        {...resource, enable: enabled, selected: usr.role.subtargets?.includes(resource.id)}
                    ));
                    const allResourcesSelected = resources.length > 0 ? resources.every((res: any) => res.selected) : isSelected;
                    const someResourcesSelected = resources.some((res: any) => res.selected);
                    return {
                        ...detail,
                        selected: isSelected,
                        enable: enabled,
                        resources: resources,
                        open: isSelected,
                        allResourcesSelected: allResourcesSelected,
                        someResourcesSelected: someResourcesSelected
                    };
                });

                this.setState({
                    newAccountUser: usr,
                    poiList: usr.storeCodes,
                    groupList: usr.groups,
                    currentPoiList: usr.storeCodes,
                    currentGroupList: usr.groups,
                    selectedRole: usr.role.scope === "local"
                        ? UserRoleList.LOCAL
                        : usr.role.scope === "group"
                            ? UserRoleList.GROUP
                            : UserRoleList.GLOBAL,
                    userRoleDetail: updatedUserRoleDetail,
                    hasAllUserModules: updatedUserRoleDetail.every((detail: any) => detail.selected)
                });
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        } else {
            const user = this.state.newAccountUser;
            user.role = new UserRole();
            user.storeCodes = [];
        }
    }

    handlePoiOpen = (event: any) => {
        this.setState({showPoiModal: !this.state.showPoiModal});
    }

    handleGroupOpen = (event: any) => {
        this.setState({showGroupModal: !this.state.showGroupModal});
    }

    handleUserFirstName = (event: any) => {
        let user = this.state.newAccountUser;
        user.firstName = event.target.value;
        this.setState({
            newAccountUser: user
        })
    }

    public handleUserLastName = (event: any) => {
        let user = this.state.newAccountUser;
        user.lastName = event.target.value;
        this.setState({newAccountUser: user});
    }

    public handleUserMail = (event: any) => {
        let user = this.state.newAccountUser;
        user.mail = event.target.value;
        this.setState({newAccountUser: user});
    }

    public handleScopeSelection = (event: any) => {
        const {userRoleDetail, newAccountUser} = this.state;
        const index = event.target.options.selectedIndex;

        const scope = this.getScopeByIndex(index);

        const updatedUser = newAccountUser
        updatedUser.role.scope = scope;

        const updatedUserRoleDetail = scope === 'global'
            ? userRoleDetail.map((detail: any) =>
                this.updateDetailForGlobalScope(detail)
            )
            : userRoleDetail;

        this.setState({
            selectedRole: index,
            newAccountUser: updatedUser,
            userRoleDetail: updatedUserRoleDetail,
        });
    };

    private getScopeByIndex(index: number): string {
        switch (index) {
            case UserRoleList.LOCAL:
                return 'local';
            case UserRoleList.GLOBAL:
                return 'global';
            case UserRoleList.GROUP:
                return 'group';
            default:
                return '';
        }
    }

    private updateDetailForGlobalScope(detail: any): any {
        if (['poi', 'campaign', 'gmb', 'gmb_post'].includes(detail.id)) {
            const resources = detail.resources.map((resource: any) => ({
                ...resource,
                enable: false,
                selected: true,
            }));
            return {
                ...detail,
                selected: true,
                enable: false,
                resources,
                open: false,
                allResourcesSelected: true,
                someResourcesSelected: false,
            };
        }
        return detail;
    }

    public handleRoleDetailSelection = (detailObj: any, isChecked: boolean) => {
        if (detailObj.id === "admin") {
            const updatedDetails = this.state.userRoleDetail.map((detail: any) =>
                this.state.selectedRole === UserRoleList.GLOBAL
                && ['poi', 'campaign', 'gmb', 'gmb_post'].includes(detail.id)
                    ? this.updateDetailForGlobalScope(detail) : (
                        {
                            ...detail,
                            selected: isChecked,
                            resources: detail.resources.map((r: any) => ({
                                ...r,
                                selected: isChecked
                            })),
                            allResourcesSelected: isChecked,
                            someResourcesSelected: isChecked
                        }
                    ));

            this.setState({
                userRoleDetail: updatedDetails,
                hasAllUserModules: isChecked
            });
        } else {
            this.setState({
                userRoleDetail: this.state.userRoleDetail.map((detail: any) => {
                    if (detail.id === detailObj.id) {
                        return {
                            ...detail,
                            selected: isChecked,
                            open: !detail.open ? true : detail.open,
                            resources: detail.resources.map((r: any) => ({
                                ...r,
                                selected: isChecked
                            })),
                            allResourcesSelected: isChecked,
                            someResourcesSelected: isChecked
                        };
                    }
                    return detail;
                })
            });
        }
    }

    handleResourceCheckboxChange = (detailId: string, resourceId: string, isChecked: boolean) => {
        this.setState((prevState) => ({
            userRoleDetail: prevState.userRoleDetail.map((detail: any) => {
                const resources = detail.resources.map((resource: any) =>
                    resource.id === resourceId ? {...resource, selected: isChecked} : resource
                );
                return detail.id === detailId
                    ? {
                        ...detail,
                        selected: isChecked ? true : detail.selected,
                        resources: resources,
                        allResourcesSelected: resources.every((res: any) => res.selected),
                        someResourcesSelected: resources.some((res: any) => res.selected)
                    }
                    : detail
            }),
        }));
    };

    updatePoiList = () => {
        this.setState({
            poiList: this.state.currentPoiList
        })
    }

    updateCurrentPoiList = (newList: string[]) => {
        this.setState({
            currentPoiList: newList,
            canValidate: newList.length > 0
        })
    }

    updateCurrentGroupList = (newList: string[]) => {
        this.setState({
            currentGroupList: newList,
            canValidate: newList.length > 0

        })
    }

    updateGroupList = () => {
        this.setState({
            groupList: this.state.currentGroupList
        })
    }

    private updateUserBySelectedRole(newUser: any) {
        const {selectedRole, userRoleDetail, poiList, groupList} = this.state;
        newUser.role = {
            ...newUser.role,
            scope: this.getRoleScope(selectedRole),
            targets: this.getRoleTargets(userRoleDetail),
            subtargets: this.getRoleSubtargets(userRoleDetail),
        };
        if (selectedRole === UserRoleList.GLOBAL && !newUser.role.targets.includes('admin')) {
            newUser.role.targets.push('admin');
        }

        newUser.storeCodes = selectedRole === UserRoleList.GLOBAL ? ['ALL_POI'] : selectedRole === UserRoleList.LOCAL ? poiList : [];
        newUser.groups = selectedRole === UserRoleList.GROUP ? groupList : [];

        return newUser;
    }

    private getRoleScope(selectedRole: number) {
        return selectedRole === UserRoleList.GLOBAL ? 'global'
            : selectedRole === UserRoleList.LOCAL ? 'local'
                : 'group';
    }

    private getRoleTargets(userRoleDetail: any[]) {
        return userRoleDetail
            .filter((detail: any) => detail.selected)
            .map((detail: any) => detail.id);
    }

    private getRoleSubtargets(userRoleDetail: any[]) {
        return userRoleDetail
            .filter((detail: any) => detail.selected)
            .flatMap((detail: any) =>
                detail.resources.filter((resource: any) => resource.selected).map((resource: any) => resource.id)
            );
    }

    public validateData(): void {
        this.setState({disableButtons: true});
        const newUser = this.updateUserBySelectedRole({...this.state.newAccountUser});

        if (this.state.clientId) {
            if (this.state.userId) {
                userAdminService.updateUser(this.state.clientId, this.state.userId, newUser)
                    .then((resp: any) => {
                        history.push('/home-page', {clientId: this.state.clientId, value: 1});
                    }).catch((error) => {
                    console.log(error);
                    history.push('/home-page', {clientId: this.state.clientId, value: 1});
                });
            } else {
                userAdminService.createUser(this.state.clientId, newUser)
                    .then((resp: any) => {
                        history.push('/home-page', {clientId: this.state.clientId, value: 1});
                    }).catch((error) => {
                    console.log(error);
                    history.push('/home-page', {clientId: this.state.clientId, value: 1});
                });
            }
        }
    }

    toggleOpen = (detailId: string) => {
        this.setState((prevState) => ({
            userRoleDetail: prevState.userRoleDetail.map((detail: any) =>
                detail.id === detailId ? {...detail, open: !detail.open} : detail
            ),
        }));
    };

    render() {
        return (
            <div className="background-admin">
                <div className="frame padlr20">
                    <div className="rows">
                        <div className="title">
                            <h1>Créer Utilisateur</h1>
                        </div>
                        <div className="oneRow">
                            <div className="subtitle left">
                                <h2>Identité de l'utilisateur</h2>
                            </div>
                            <div className="col">
                                <div className="inputs">
                                    <div className="firstName inputs">
                                        <label className="w237">
                                            Prénom
                                            <input type="text" name="firstName"
                                                   value={this.state.newAccountUser.firstName}
                                                   onChange={this.handleUserFirstName}
                                                   disabled={this.state.userId ? true : false}/>
                                        </label>
                                    </div>
                                    <div className="lastName inputs">
                                        <label className="w237">
                                            Nom
                                            <input type="text" name="name" value={this.state.newAccountUser.lastName}
                                                   onChange={this.handleUserLastName}
                                                   disabled={this.state.userId ? true : false}/>
                                        </label>
                                    </div>
                                </div>
                                <div className="mail inputs">
                                    <label className="w237">
                                        Mail
                                        <input type="text" name="name" value={this.state.newAccountUser.mail}
                                               onChange={this.handleUserMail}
                                               disabled={this.state.userId ? true : false}/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="oneRow">
                            <div className="subtitle left">
                                <h2>Méthode de connexion</h2>
                            </div>
                            <div className="col inputs left">
                                <label className="w237">{this.state.newAccountUser.isSso ? 'SSO' : 'Standard'}</label>
                            </div>
                        </div>
                        <div className="oneRow">
                            <div className="subtitle left">
                                <h2>Niveau du rôle</h2>
                            </div>
                            <div className="col inputs left">
                                <select onChange={this.handleScopeSelection}
                                        value={this.state.newAccountUser.role ? this.state.newAccountUser.role.scope : ""}>
                                    <option hidden value="">Veuillez sélectionner</option>
                                    <option id="local" value="local">Local</option>
                                    <option id="group" value="group">Groupe</option>
                                    <option id="global" value="global">Global</option>
                                </select>
                            </div>
                        </div>
                        {this.state.selectedRole != 0 && (
                            <div className="oneRow">
                                <div className="subtitle left">
                                    <h2>Détail du rôle</h2>
                                </div>
                                <div className="col left">
                                    <div>
                                        {this.state.userRoleDetail.map((detail: any) => (
                                            <div key={detail.id}>
                                                <div id={detail.id} className="inputs">
                                                    <Checkbox
                                                        checkedValue={detail.allResourcesSelected}
                                                        indeterminate={!detail.allResourcesSelected && detail.someResourcesSelected}
                                                        disable={!detail.enable}
                                                        onChange={(isChecked) => this.handleRoleDetailSelection(detail, isChecked)}
                                                    />
                                                    <span onClick={() => this.toggleOpen(detail.id)}
                                                          style={{cursor: 'pointer'}}>
                                                        {detail.label}
                                                        <span
                                                            style={{color: '#15afad'}}>{detail.resources?.length > 0 ? (detail.open ? ' ▼' : ' ▶') : ""} </span>
                                                    </span>
                                                </div>

                                                {/* module resources if present */}
                                                {detail.open && detail.resources && detail.resources.length > 0 && (
                                                    <div style={{marginLeft: '40px'}}>
                                                        {detail.resources.map((resource: any) => (
                                                            <div key={resource.id} id={resource.id} className="inputs">
                                                                <Checkbox
                                                                    checkedValue={resource.selected}
                                                                    disable={!resource.enable}
                                                                    onChange={(isChecked) => this.handleResourceCheckboxChange(detail.id, resource.id, isChecked)}
                                                                />
                                                                {resource.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        <div className="inputs">
                                            <Checkbox
                                                checkedValue={this.state.hasAllUserModules}
                                                disable={false}
                                                onChange={(isChecked) => this.handleRoleDetailSelection({id: 'admin'}, isChecked)}
                                            />
                                            <span style={{cursor: 'pointer'}}>Tous les droits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.selectedRole === UserRoleList.LOCAL
                            && <div className="oneRow">
                                <div className="subtitle left">
                                    <h2>Sélectionnez les établissements concernés par ce rôle</h2>
                                </div>
                                <div className="">
                                    <div>
                                        <div>{this.state.poiList.length + " établissements sélectionnés"} </div>
                                        <button className="customButton valid-btn w210 margin5"
                                                onClick={this.handlePoiOpen}> Ajouter des établissements
                                        </button>
                                        {this.state.showPoiModal &&
                                            <Modal onClose={this.handlePoiOpen}
                                                   onValid={this.updatePoiList}
                                                   isShown={true}
                                                   canValidate={this.state.canValidate}
                                                   headerText="Ajouter des établissements manuellement"
                                                   modalContent={<AddPoiModal updatePoiList={this.updateCurrentPoiList}
                                                                              poiList={this.state.poiList}
                                                                              updateText='Saisissez les store code des établissements (appuyez sur la touche entrée pour valider)'></AddPoiModal>}
                                            />
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.selectedRole === UserRoleList.GROUP
                            && <div className="oneRow">
                                <div className="subtitle left">
                                    <h2>Sélectionnez les groupes concernés par ce rôle</h2>
                                </div>
                                <div className="">
                                    <div>
                                        <div>{this.state.groupList.length + " groupes sélectionnés"} </div>
                                        <button className="customButton valid-btn w210 margin5"
                                                onClick={this.handleGroupOpen}> Ajouter des groupes
                                        </button>
                                        {this.state.showGroupModal &&
                                            <Modal onClose={this.handleGroupOpen}
                                                   onValid={this.updateGroupList}
                                                   canValidate={this.state.canValidate}
                                                   isShown={true}
                                                   headerText="Ajouter des groupes manuellement"
                                                   modalContent={<AddPoiModal
                                                       updatePoiList={this.updateCurrentGroupList}
                                                       poiList={this.state.groupList}
                                                       updateText='Saisissez les codes des groupes (appuyez sur la touche entrée pour valider)'>
                                                   </AddPoiModal>}
                                            />
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                        <div style={{marginBottom: '40px', marginTop: '20px'}}>
                            <div>
                                <button className="customButton cancel-btn w120 margin5 right"
                                        onClick={() => history.push('/home-page', {
                                            clientId: this.state.clientId,
                                            value: 1
                                        })}
                                        disabled={this.state.disableButtons}> Annuler
                                </button>
                                <button className="customButton valid-btn w120 margin5 right"
                                        onClick={this.validateData}
                                        disabled={this.state.disableButtons}> Valider
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

export default UserCreateUpdate;